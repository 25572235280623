import request from '@/utils/request'
import apiConfig from '@/api/apiconfig'
/**
 * 登录
 */
export function reqShantouUser(data) {
  return request({
    url: `${apiConfig.ZY}/shantouUser/login`,
    method: 'post',
    token: sessionStorage.getItem('shantouToken'),
    data
  })
}
/**
 * 获取套餐信息
 */
export function reqGetMealInfo() {
  return request({
    url: `${apiConfig.ZY}/shantouOrder/getMealInfo`,
    token: sessionStorage.getItem('shantouToken'),
    method: 'get'
  })
}
/**
 * 登记下发礼品卡
 */
export function reqCreateCoupon(data) {
  return request({
    url: `${apiConfig.ZY}/shantouOrder/createCoupon`,
    method: 'post',
    token: sessionStorage.getItem('shantouToken'),
    data
  })
}
/**
 * 登记下发礼品卡
 */
export function reqShantouOrder(data) {
  return request({
    url: `${apiConfig.ZY}/shantouOrder/registerRecord`,
    method: 'post',
    token: sessionStorage.getItem('shantouToken'),
    data
  })
}
