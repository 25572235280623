<template>
  <div class="login">
    <img src="@/assets/img/shantou/shantou-login-title.png" />
    <form>
      <input placeholder="请输入账号" v-model="submitForm.username" />
      <input
        placeholder="请输入密码"
        type="password"
        v-model="submitForm.password"
      />
      <div class="submit" @click="submitMeds">登录</div>
    </form>
  </div>
</template>

<script>
import { reqShantouUser } from '@/api/shantou-project'
export default {
  name: 'ShantouLogin',
  data() {
    return {
      submitForm: {
        username: undefined,
        password: undefined
      }
    }
  },
  created() {},
  methods: {
    async submitMeds() {
      try {
        const data = await reqShantouUser(this.submitForm)
        if (data && data.code === 0) {
          sessionStorage.setItem('shantouToken', data.data)
          this.$router.push({ name: 'ShantouReleasePage' })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.login {
  height: 100vh;
  background: url(../../assets/img/shantou/shantou-login-bg.png) bottom center
    no-repeat;
  background-size: contain;
  & > img {
    width: 11.06vw;
    display: block;
    margin: 0vw auto 12.26vw;
    padding-top: 17.2vw;
  }
  input {
    width: 77.33vw;
    padding: 0 5vw 0 10vw;
    line-height: 13.3vw;
    border-radius: 10.4vw;
    background: #fafafa;
    margin: 0 auto 6.8vw;
    display: block;
  }
  .submit {
    width: 77.33vw;
    line-height: 13.3vw;
    background: linear-gradient(134deg, #3d6efd 0%, #3d6efd 100%);
    box-shadow: 0px 6px 13.5px 0px #beceff;
    border-radius: 10.4vw;
    margin: 11.06vw auto 0;
    text-align: center;
    color: #fff;
    font-size: 4vw;
  }
}
</style>
